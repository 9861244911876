$bp-largest: 75em; //1200px / 16 = 75em
$bp-large: 62.5em;  //1000px
$bp-medium: 50em; //800px
$bp-small: 43.5em; //600px

.About {
  width: 100%;

&__container{
    display: flex;
    justify-content: center;
}
  &__image {
    margin: 3rem 0;
    width: 50%;
    border: 2px solid grey;
    padding: 2rem;

    @media only screen and(max-width: $bp-medium){
      width: 70%;

    }
    @media only screen and(max-width: $bp-small){
      width: 90%;
      height: auto;
    }
  }
  &__content{
    text-align: center;
    color: white;
    font-size: 3rem;

  }
  &__text{
    text-align: center;
  }
  &__email{
    color: grey;
    font-size: 1.2rem;
  }
}
