.Home-video {
  &__content{
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  z-index: -10;
  opacity: .2;
  overflow: hidden;


    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}