@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400&display=swap);
body {
  margin: 0;
  font-family: 'Source Sans Pro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
    100deg,
    rgba(250, 214, 195, .8) 30%,
    #b0eae8 120%
  )
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.brand{font-size:2rem}.nav-item{margin:1.2rem 0rem;font-size:1.45rem}.link{margin-top:1.8rem}.home-link{margin-left:0}.home-link::after{text-decoration:none}

.Zenquote{margin:0;padding:0;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;height:90vh}.Zenquote__text{font-family:"Source sans-pro";position:relative;font-family:sans-serif;text-transform:uppercase;font-size:5em;letter-spacing:4px;background:linear-gradient(90deg, #000, #fff, #000);background-repeat:no-repeat;background-size:80%;-webkit-animation:animate 5s linear infinite;animation:animate 5s linear infinite;-webkit-background-clip:text;-webkit-text-fill-color:rgba(255,255,255,0)}@media only screen and (max-width: 43.5em){.Zenquote__text{font-size:2em}}@-webkit-keyframes animate{0%{background-position:-500%}100%{background-position:500%}}@keyframes animate{0%{background-position:-500%}100%{background-position:500%}}

.Home-video__content{position:absolute;top:0;left:0;height:auto;width:100%;z-index:-10;opacity:.2;overflow:hidden;height:100%;width:100%;object-fit:cover}

.About{width:100%}.About__container{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center}.About__image{margin:3rem 0;width:50%;border:2px solid grey;padding:2rem}@media only screen and (max-width: 50em){.About__image{width:70%}}@media only screen and (max-width: 43.5em){.About__image{width:90%;height:auto}}.About__content{text-align:center;color:white;font-size:3rem}.About__text{text-align:center}.About__email{color:grey;font-size:1.2rem}

.row{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;width:100%}.cards{margin:2rem 2rem;box-shadow:0 2rem 2rem rgba(0,0,0,0.3);transition:.4s}.cards:hover{-webkit-transform:scale(1.1);transform:scale(1.1)}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

