.row {
  display: flex;
  justify-content: center;
  width: 100%;
}

.cards {
margin: 2rem 2rem;
box-shadow: 0 2rem 2rem rgba(0,0,0,.3);
transition: .4s;
}

.cards:hover{
  transform: scale(1.1);
}

