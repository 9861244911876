$bp-small: 43.5em; //600px

.Zenquote{
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height:90vh;

  &__text{
    font-family: "Source sans-pro";
    position: relative;
    font-family: sans-serif;
    text-transform: uppercase;
    font-size: 5em;
    letter-spacing: 4px;
    // overflow: hidden;
    background: linear-gradient(90deg, #000, #fff, #000);
    background-repeat: no-repeat;
    background-size: 80%;
    animation: animate 5s linear infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);

    @media only screen and(max-width: $bp-small){
      font-size: 2em;
    }
  }
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}